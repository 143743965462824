$colors-list: (
	neutral: (
		1: #ffffff,
		2: #fafafa,
		3: #f0f0f0,
		4: #e6e6e6,
		5: #ededed,
		6: #bdbdbd,
		7: #9e9e9e,
		8: #6d6d6d,
		9: #4f4f4f,
		10: #171717,
		11: #333,
	),
	orange: (
		1: #f7efe6,
		2: #ddbe96,
		3: #cea36b,
		4: #ab6100,
		5: #784400,
		6: #bd7e00,
		7: #e59900,
		8: #c9671d,
		600: #c9671d,
		700: #f07400,
		9: #e06f06,
	),
);

@each $baseColor, $variants in $colors-list {
	@each $variantName, $value in $variants {
		.bg-#{$baseColor}-#{$variantName} {
			background-color: #{$value} !important;
		}
		.border-color-#{$baseColor}-#{$variantName} {
			border-color: #{$value};
		}
		.svg-path-fill-#{$baseColor}-#{$variantName} {
			path {
				fill: #{$value};
			}
		}
		.#{$baseColor}-#{$variantName} {
			color: #{$value} !important;
		}
	}
}
