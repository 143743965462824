@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url("https://fonts.googleapis.com/css2?family=Material+Icons+Outlined");
@import url("https://fonts.googleapis.com/css2?family=Material+Icons+Round");
@import url("https://fonts.googleapis.com/css2?family=Material+Icons+Sharp");
@import url("https://fonts.googleapis.com/css2?family=Material+Icons+Two+Tone");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
@import "./base/colors.scss";
@import "./base/spacings.scss";
@import "./base/flex.scss";
@import "./base/typography.scss";
@import "./base/borders.scss";
@import "./base/position.scss";
@import "./base/overflow.scss";

body {
	margin: 0;
	background: #f9f8f8;
	font-family: "Manrope", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
		"Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

:root {
	@each $baseColor, $variants in $colors-list {
		@each $variantName, $value in $variants {
			--#{$baseColor}-#{$variantName}: #{$value};
		}
	}
}

.pointer,
.cursor-pointer {
	cursor: pointer !important;
}

.cursor-not-allowed {
	cursor: not-allowed !important;
}

.overflow {
	overflow: auto;
}

.col {
	flex: 1 0 0%;
}

.w-100 {
	width: 100% !important;
}

.w-50 {
	width: 50% !important;
}

.w-90 {
	width: 90% !important;
}

.popover-p-0 .ant-popover-inner-content {
	padding: 0 !important;
}

.w-fit-content {
	width: fit-content !important;
}

.w-max-content {
	width: max-content;
}

.w-auto {
	width: auto;
}

.h-100 {
	height: 100%;
}

.display-none {
	display: none !important;
}

.h-max-content {
	height: max-content;
}

.h-fit-content {
	height: fit-content;
}

.disabled {
	cursor: default;
	pointer-events: none;
	user-select: none;
}

.white-space-nowrap {
	white-space: nowrap;
}

.border-solid {
	border: 1px solid;
}

.border-solid {
	border: 1px solid;
}

.hidden {
	display: none !important;
}

.visibility-hidden {
	display: none !important;
}

.flex-1 {
	flex: 1;
}

.break-word {
	word-break: break-word;
}

.scrollbar-css {
	::-webkit-scrollbar {
		width: 5px;
		background-color: #fff;
		border-radius: 10px;
		z-index: 10;
	}

	::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		border-radius: 15px;
		background-color: #fff;
	}

	::-webkit-scrollbar-thumb {
		border-radius: 15px;
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		background-color: auto;
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: darkgray;
		border-radius: 15px;
	}
}
