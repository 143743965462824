$fontSize: (
	10: 10px,
	12: 12px,
	14: 14px,
	16: 16px,
	24: 24px,
	20: 20px,
	18: 18px,
	30: 30px,
	32: 32px,
);

$textAlignment: (left, right, center, justify, initial, inherit);

$textTransform: (capitalize, uppercase, lowercase, none);

@each $key, $value in $fontSize {
	.fs-#{$key} {
		font-size: #{$value} !important;
	}
}

$fontWeights: (400, 500, 600, 700, 800, 900);

@each $key in $fontWeights {
	.fw-#{$key} {
		font-weight: #{$key};
	}
}

@each $value in $textAlignment {
	.txt-align-#{$value} {
		text-align: #{$value} !important;
	}
}

@each $key in $textTransform {
	.txt-transform-#{$key} {
		text-transform: #{$key};
	}
}

.w-bold {
	font-weight: 700 !important;
}

.w-medium {
	font-weight: 500 !important;
}

.w-semi-bold {
	font-weight: 600 !important;
}

.w-regular {
	font-weight: 400 !important;
}

.line-through {
	text-decoration: line-through;
}

.text-center {
	text-align: center;
}

.capitalize {
	text-transform: capitalize;
}
